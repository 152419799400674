<template>
<section>
  <div class="submit-form" width="10000">
    <div>
      <div class="form-group">
        <label for="description">Domain</label>
        <input
          v-on:keyup.enter="validate_domain"
          class="form-control"
          id="domain"
          required
          v-model="jarm.prevalid_domain"
          name="domain"
        />
      </div>

      <button @click="validate_domain" class="btn btn-success">Submit</button>
    </div>
    
  </div>
  <div class="jarm_results">
    <h3 v-if = submitted> Domain {{jarm.domain}} JARM: {{domain_jarm}} </h3>
    <li v-for="(data, id) in jarm_result" :key=id>Percentage Simlarity using {{data.type}} to <b>{{data.tool}}</b> {{data.similar}} % </li>
     See <a href="https://itnext.io/string-similarity-the-basic-know-your-algorithms-guide-3de3d7346227">here</a> for a breakdown of different methods for string comparison 
  </div>
  <div v-if= returned_results>
        <div class="centered"><h3> Substring Comparison </h3> </div>
  <div class="split left" v-if = returned_results>
    <div class="left"> 
       <li v-for="(data, id) in sub_data" :key=id>Substring similarity using {{data.type}} to <b>{{data.tool}}</b> {{data.similar}} %</li>
    </div>
  </div>
  <div class="split right" > 
    <div class="left" v-if = returned_results> <b>Substring similarity </b><p />This is based on the concept that the first 30 characters of a JARM hash are comprised of trigrams of a Hybrid fuzzy hash algorithm. Substring comparison provides a potentially closer match on individual tooling identification  
    <div><p />If you believe this domain to be a Cobalt Strike C2 click here to get the beacon config <p /><button @click="get_c2" class="btn btn-success">Get Cobalt c2?</button></div>
    <pre v-if: c2_results> {{c2_config_data}}</pre>
    </div>
  </div>
  </div>
  </section>
</template>

<script>
import axios from "axios";


export default {
  name: "check-jarm",
  data() {
    return {
      jarm: {
        prevalid_domain: "",
        domain: "",
      },
      submitted: false,
      returned_results: false,
      jarm_result: [],
      domain_jarm: "",
      sub_data: [],
      c2_config_data: "",
    };
  },
  methods:{
    validate_domain(){
    var domain = this.jarm.prevalid_domain
    let valid_domain = domain.match(/^[A-Z,a-z,\\,.,0-9]*/)
    this.jarm.domain = valid_domain[0]
    this.submitted = true
    this.check_jarm()
    this.check_substring()
    //this.get_c2()
  },
  check_jarm: async function(){
    var data = {
      domain: this.jarm.domain
    }
    let result = await axios({
      method: "post",
      url: "https://win.ntdll.top/api",
      data: data,
    });
    this.domain_jarm = JSON.stringify(result.data[0].orig_jarm)
    let dj = JSON.stringify(result.data[0].orig_jarm)
    console.log(dj)
    this.result = result.data
    this.jarm_result = result.data;
    this.get_sim()
  },
  check_substring: async function(){
   var data = {
      domain: this.jarm.domain
    }
    let result = await axios({
      method: "post",
      url: "https://win.ntdll.top/api/sub",
      data: data,
    });
    let sub_res_json = JSON.stringify(result.data)
    console.log(sub_res_json)
    this.sub_data = result.data
    this.returned_results = true
  },
  get_c2: async function(){
  var data = {
    domain: this.jarm.domain
  }
  let result = await axios({
    method:'post',
    url: "https://win.ntdll.top/api/getc2",
    data: data,
  });
  let c2_config = result.data
  this.c2_config_data = c2_config
  },

  get_sim: async function(){
    var data = {
      domain: this.jarm.domain,
      jarm: this.domain_jarm
    }
    let result = await axios({
    method:'post',
    url: "https://win.ntdll.top/api/checkSim",
    data: data,
    });
    let sim_data = result.data
    console.log(sim_data) 
  }
},

  template: '<div> {{ jarm_result }} </div>'
};
</script>

<style>
.submit-form {
  max-width: 50em;
  margin: auto;
}

.jarm_results {
  max-width: 50em;
  margin: auto;
  /* position: fixed; */
}

/* Split the screen in half */
.split {
  height: 30%;
  width: 50%;
  position: fixed;
  z-index: 0;
  top: 1;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.left {
  left: 0;
  /* text-align: left; */
  /* background-color: #111; */
}

/* Control the right side */
.right {
  right: 0;
  /* background-color: red; */
}

/* If you want the content centered horizontally and vertically */
.centered {
  position: relative;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -0%);
  text-align: center;
}

/* Style the image inside the centered container, if needed */
.centered img {
  width: 150px;
  border-radius: 50%;
}

</style>